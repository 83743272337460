import { NgModule } from '@angular/core';
import { MODULES } from 'src/app/pages/base.import';
import { TermsAndConditionsPage } from 'src/app/pages/terms-and-conditions/terms-and-conditions.page';
import { PdfViewerModule } from 'ng2-pdf-viewer';


@NgModule({
  declarations: [
    TermsAndConditionsPage
  ],
  imports: [
    MODULES,
    PdfViewerModule
  ],
  exports: [
    TermsAndConditionsPage
  ],
  providers: [
  ],
  entryComponents: [
    TermsAndConditionsPage
  ]
})
export class RegisterModule { }
