import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserInfoServiceService {

  cognitoUserInfo: any;
  constructor() { }

  setCognitoUserInfo(userInfo: any) {
    this.cognitoUserInfo = userInfo;
  }

  getCognitoUserInfo() {
    return this.cognitoUserInfo;
  }
}
